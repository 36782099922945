.carousel {
    position: relative;
    width: 100%; /* Set your desired width */
    height: 500px; /* Set your desired height */
    overflow: hidden;
}

.photo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.photo.visible {
    opacity: 1;
}
